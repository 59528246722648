import React from "react"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"

const ExpensesSEO = () => {
  return (
    <Helmet>
      {/* General Expense Keywords */}
      <meta 
        name="keywords" 
        content="Expense management software, Corporate credit cards, Business expense solutions, Nordea First Card benefits, Eurocard corporate services, Mynt expense management, Pleo business cards, eTasku receipt management, Digital expense reporting, Automated expense tracking"
      />

      {/* Additional Value Keywords */}
      <meta 
        name="keywords" 
        content="Employee expense management, Mobile expense apps, Expense reporting tools, Business spending control, Expense reimbursement solutions, Financial management tools, Company expense solutions, Travel expense management, Expense management integration, Corporate card management"
      />

      {/* Finnish Market Keywords */}
      <meta 
        name="keywords" 
        content="Expense management Finland, Corporate cards Finland, Business expense solutions Finland, Nordea First Card features, Eurocard corporate services, Mynt expense management, Pleo business cards, eTasku receipt management, Digital expense reporting Finland, Automated expense tracking Finland"
      />

      {/* Additional Finnish Keywords */}
      <meta 
        name="keywords" 
        content="Corporate credit cards Finland, Business travel expense solutions Finland, Employee expense management Finland, Expense management software Finland, Mobile expense apps Finland, Expense reporting tools Finland, Business spending control Finland, Expense reimbursement Finland, Financial management tools Finland, Company expense solutions Finland"
      />
    </Helmet>
  )
}

const ExpensesSEOFi = () => {
  return (
    <Helmet>
      {/* Finnish Market Keywords */}
      <meta 
        name="keywords" 
        content="Expense management Finland, Corporate cards Finland, Business expense solutions Finland, Nordea First Card features, Eurocard corporate services, Mynt expense management, Pleo business cards, eTasku receipt management, Digital expense reporting Finland, Automated expense tracking Finland"
      />

      {/* Additional Finnish Keywords */}
      <meta 
        name="keywords" 
        content="Corporate credit cards Finland, Business travel expense solutions Finland, Employee expense management Finland, Expense management software Finland, Mobile expense apps Finland, Expense reporting tools Finland, Business spending control Finland, Expense reimbursement Finland, Financial management tools Finland, Company expense solutions Finland"
      />

      {/* Finnish Budget Keywords */}
      <meta 
        name="keywords" 
        content="Ilmainen kulujen seurantasovellus, Edulliset yrityskortit, Budjettiystävälliset kulujen hallintatyökalut, Yksinkertainen kulujen hallinta, Ilmainen kuitinhallintasovellus, Edulliset yrityksen kuluratkaisut, Pienyrityksen kulujen seuranta, Kulujen hallinta startupeille, Ilmainen matkakulujen hallinta, Tee-se-itse kuluraportointi"
      />

      {/* Additional Finnish Budget Keywords */}
      <meta 
        name="keywords" 
        content="Kulujen hallinta freelancereille, Avoimen lähdekoodin kulutyökalut, Ilmainen hyvityslaskuri, Edullinen työntekijöiden kulujen seuranta, Freelancerin kuitin organisointi, Edulliset taloudenhallintasovellukset, Kuluraportoinnin mallit, Yksinkertaiset mobiilikulusovellukset, Pienten tiimien kuluratkaisut, Ilmainen verkkokulukalkulaattori"
      />
    </Helmet>
  )
}

const ExpensesSEOSv = () => {
  return (
    <Helmet>
      {/* Swedish General Keywords */}
      <meta 
        name="keywords" 
        content="Utgiftshantering Sverige, Företagskort Sverige, Affärsutgiftslösningar Sverige, Digital utgiftsrapportering Sverige, Automatiserad utgiftsspårning Sverige, Företagskreditkort Sverige, Affärsreseutgiftslösningar Sverige, Anställdas utgiftshantering Sverige, Utgiftshanteringsprogram Sverige, Mobila utgiftsappar Sverige"
      />

      {/* Swedish Additional Keywords */}
      <meta 
        name="keywords" 
        content="Utgiftsrapporteringsverktyg Sverige, Företagsutgiftskontroll Sverige, Utgiftsersättning Sverige, Ekonomihanteringsverktyg Sverige, Företagsutgiftslösningar Sverige, Kvittohantering företag, Digitala utlägg företag, Automatisk utgiftsspårning, Företagskort administration, Reseräkningar digitalt"
      />

      {/* Swedish Budget Keywords */}
      <meta 
        name="keywords" 
        content="Gratis verktyg för utgiftshantering, Prisvärda företagskort, Budgetvänliga utgiftshanteringsverktyg, Enkel hantering av utgifter, Gratis kvittohanteringsapp, Prisvärda lösningar för företagsutgifter, Utgiftshantering för småföretag, Utgiftshantering för startups, Gratis verktyg för reseutgifter, DIY utgiftsrapportering"
      />

      {/* Additional Swedish Budget Keywords */}
      <meta 
        name="keywords" 
        content="Utgiftshantering för frilansare, Öppen källkod för utgiftshantering, Gratis ersättningskalkylator, Prisvärda verktyg för anställdas utgifter, Frilansares kvittohantering, Prisvärda appar för ekonomihantering, Mall för utgiftsrapportering, Enkla appar för mobila utgifter, Lösningar för små team och utgifter, Gratis onlineutgiftskalkylator"
      />
    </Helmet>
  )
}

export const ExpensesMetaTags = () => {
  const { locale } = useParams()
  
  if (locale === 'fi') {
    return <ExpensesSEOFi />
  } else if (locale === 'sv') {
    return <ExpensesSEOSv />
  } else {
    return <ExpensesSEO />
  }
}
