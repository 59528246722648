import React, { useEffect, useState } from 'react'
import { Navigation } from '../Navigation'
import { Stack } from '../common/components/Spacing'
import { Footer } from '../Footer'
import { HeroSection } from './components/HeroSection'
import { VirtualCard } from './components/VirtualCard'
import { HubSubscriptions } from './components/HubSubscriptions'
import { TransparentPricing } from './components/TransparentPricing'
import { ContactUs } from '../Expenses/components/ContactUs'
import { ThingsToKnow } from '../Expenses/components/ThingsToKnow'
import { SimplestSolution } from '../SimplestSolution'
import { MWrapper } from '../common/styled'
import { useIsMobile } from '../common/hooks'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { useParams } from 'react-router-dom'
import { SubscriptionsMetaTags } from './components/MetaTags'

export const Subscriptions = () => {
  const isMobile = useIsMobile()
  const { locale } = useParams<string>()
  const [prismicData, setPrismicData] = useState<any>(null)
  const [heroSectionAppeared, setHeroSectionAppeared] = useState(false)
  const [cardSectionAppeared, setCardSectionAppeared] = useState(false)


  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const pageId =
        locale === 'en'
          ? 'ZiZ7qhAAAJu0V6KA'
          : locale === 'fi'
            ? 'ZiZ7sRAAAEO1V6Kb'
            : 'ZiZ7thAAAA61V6K2'

      const data =
        await prismicClient.getByID(pageId, {
          lang: prismicLocale,
        })
      setPrismicData(data)
    }

    getPrismicData()
  }, [locale])
  return (
    <div>
      <SubscriptionsMetaTags />
      <Navigation />
      <MWrapper $isMobile={isMobile}>
        <Stack gap={25}>
          <HeroSection
            setHeroSectionAppeared={setHeroSectionAppeared}
            translations={{
              heroTitle: prismicData?.data.product_page_header[0]?.text,
              heroDescription: prismicData?.data.product_page_description[0]?.text,
              heroCta: prismicData?.data.product_page_cta[0]?.text,
            }} />
          <div>
            <VirtualCard setAppeared={setCardSectionAppeared} isReady={heroSectionAppeared} />
            <HubSubscriptions isReady={heroSectionAppeared && cardSectionAppeared} />
          </div>
          <TransparentPricing
            translations={{
              title: prismicData?.data.pricing_header[0]?.text,
              description: prismicData?.data.pricing_description[0]?.text,
              option1: prismicData?.data.pricing_row_1[0]?.text,
              price1: prismicData?.data['pricing_row_1.2'][0]?.text,
              option2: prismicData?.data.pricing_row_2[0]?.text,
              price2: prismicData?.data['pricing_row_2.2'][0]?.text,
              active_user_disclaimer: prismicData?.data.pricing_disclaimer[0]?.text,
              cta: prismicData?.data.pricing_cta[0]?.text,
            }}
          />
          <ContactUs />
          <ThingsToKnow />
          <SimplestSolution shouldStartAnimation={heroSectionAppeared} />
        </Stack>
        <Footer />
      </MWrapper>
    </div>
  )
}
