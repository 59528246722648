import React, { useEffect, useState } from 'react'
import { Navigation } from '../Navigation'
import { Stack } from '../common/components/Spacing'
import { Footer } from '../Footer'
import { ContactUs } from '../Expenses/components/ContactUs'
import { ThingsToKnow } from '../Expenses/components/ThingsToKnow'
import { SimplestSolution } from '../SimplestSolution'
import { HeroSection } from './components/HeroSection'
import { AppClaims } from './components/AppClaims'
import { TransparentPricing } from './components/TransparentPricing'
import { HubClaims } from './components/HubClaims'
import { useIsMobile } from '../common/hooks'
import { MWrapper } from '../common/styled'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { useParams } from 'react-router-dom'
import { ClaimsMetaTags } from './components/MetaTags'

export const Claims = () => {
  const isMobile = useIsMobile()
  const { locale } = useParams<string>()
  const [prismicData, setPrismicData] = useState<any>(null)
  const [heroSectionAppeared, setHeroSectionAppeared] = useState(false)
  const [appSectionAppeared, setAppSectionAppeared] = useState(false)

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const pageId =
        locale === 'en'
          ? 'ZiZ8DRAAAOmyV6RA'
          : locale === 'fi'
            ? 'ZiZ8GBAAAOmyV6R0'
            : 'ZiZ8HBAAAJu0V6SJ'

      const data =
        await prismicClient.getByID(pageId, {
          lang: prismicLocale,
        })
      setPrismicData(data)
    }

    getPrismicData()
  }, [locale])

  return (
    <div>
      <ClaimsMetaTags />
      <Navigation />
      <MWrapper $isMobile={isMobile}>
        <Stack gap={25}>
          <HeroSection
            setHeroSectionAppeared={setHeroSectionAppeared}
            translations={{
              heroTitle: prismicData?.data.product_page_header[0]?.text,
              heroDescription: prismicData?.data.product_page_description[0]?.text,
              heroCta: prismicData?.data.product_page_cta[0]?.text,
            }}
          />
          <div>
            <AppClaims setAppeared={setAppSectionAppeared} isReady={heroSectionAppeared} />
            <HubClaims isReady={heroSectionAppeared && appSectionAppeared} />
          </div>
          <TransparentPricing
            translations={{
              title: prismicData?.data.pricing_header[0]?.text,
              description: prismicData?.data.pricing_description[0]?.text,
              option1: prismicData?.data.pricing_row_1[0]?.text,
              price1: prismicData?.data['pricing_row_1.2'][0]?.text,
              active_user_disclaimer: prismicData?.data.pricing_disclaimer[0]?.text,
              cta: prismicData?.data.pricing_cta[0]?.text,
            }}
          />
          <ContactUs />
          <ThingsToKnow />
          <SimplestSolution shouldStartAnimation={heroSectionAppeared} />
        </Stack>
        <Footer />
      </MWrapper>
    </div>
  )
}
