import React from "react"
import { Helmet } from "react-helmet"
import { useParams, useLocation } from "react-router-dom"

const GeneralSEO = () => {
  return (
    <Helmet>
      {/* General high-intent keywords meta tag */}
      <meta 
        name="keywords" 
        content="Best expense management solutions, Corporate expense tracking, Business card expense control, Automated receipt tracking, Digital expense reporting software, Employee expense management tools, Expense tracker for small businesses, Online receipt storage solutions, Simplify business expenses, Best apps for expense management"
      />
      
      {/* Free and Low-Cost keywords */}
      <meta 
        name="keywords" 
        content="Budget-friendly expense tools, Best free apps for business expenses, Open-source expense management, Affordable corporate card solutions, Low-cost business financial tools, Expense tracking for startups, DIY business expense management, Free mobile apps for expense control, Affordable travel expense tracking"
      />

      {/* Action-Oriented keywords */}
      <meta 
        name="keywords" 
        content="Download expense tracking app, Start free expense management trial, Sign up for business card services, Get automated receipt management, Subscribe to expense reporting tools, Switch to digital expense tracking, Claim employee expenses quickly, Reduce business card fees, Choose the best expense tracker, Optimize company spending"
      />

      {/* Long-Tail keywords */}
      <meta 
        name="keywords" 
        content="How to choose the best expense tracker, Best receipt management tools for 2025, Cost-effective solutions for business expenses, Free tools for managing employee expenses, Affordable corporate cards with rewards, Simplify expense reporting with apps, Best tools for freelancer expense tracking, How to automate receipt collection, Affordable business expense software, Manage team expenses on a budget"
      />

      {/* Trending Keywords */}
      <meta 
        name="keywords" 
        content="AI-powered expense management, Cloud-based expense tracking tools, Mobile-first expense tracking apps, Digital wallets for corporate expenses, Expense apps for remote teams, Sustainable expense management solutions, Blockchain for business expenses, Expense tracking with OCR technology, Gamified financial tools for businesses, Best eco-friendly business cards"
      />

      {/* Business Card Keywords */}
      <meta 
        name="keywords" 
        content="Business credit cards Finland, Corporate cards Sweden, Best business credit cards, Corporate credit solutions, Business debit cards, Company expense cards, Corporate payment cards, Business card comparison, Corporate card benefits, Business card offers"
      />

      {/* Card Competitor Comparison Keywords */}
      <meta 
        name="keywords" 
        content="Aktia business credit card, Oma-SP corporate card, Danske Bank business card, Handelsbanken corporate card, SEB business credit solutions, OP business card services, Nordea corporate cards, S-Pankki business credit, Compare Aktia and Nordea business cards, Danske Bank vs. Handelsbanken corporate cards"
      />

      {/* Card Competitor-Specific Keywords */}
      <meta 
        name="keywords" 
        content="Aktia business card reviews, Oma-SP corporate card features, Danske Bank business card benefits, Handelsbanken corporate card services, SEB business credit card offers, OP business card application, Nordea corporate card rewards, S-Pankki business credit card details, Aktia vs. Nordea business cards, Danske Bank vs. OP corporate cards"
      />

      {/* Card Feature-Specific Keywords */}
      <meta 
        name="keywords" 
        content="Business card rewards, Corporate card cashback, Business card travel insurance, Corporate card expense management, Business card credit limits, Corporate card online banking, Business card fraud protection, Corporate card contactless payments, Business card foreign transaction fees, Corporate card annual fees"
      />

      {/* Card Industry-Specific Keywords */}
      <meta 
        name="keywords" 
        content="Business cards for SMEs, Corporate cards for startups, Business credit for freelancers, Corporate cards for large enterprises, Business cards for non-profits, Corporate cards for tech companies, Business cards for healthcare professionals, Corporate cards for retail businesses, Business cards for consultants, Corporate cards for legal firms"
      />

      {/* Card Geographic-Specific Keywords */}
      <meta 
        name="keywords" 
        content="Business credit cards in Helsinki, Corporate cards in Stockholm, Business cards in Tampere, Corporate credit in Gothenburg, Business cards in Turku, Corporate cards in Malmö, Business credit in Oulu, Corporate cards in Uppsala, Business cards in Espoo, Corporate credit in Västerås"
      />

      {/* Card Long-Tail Keywords */}
      <meta 
        name="keywords" 
        content="How to choose a business credit card in Finland, Best corporate cards for Swedish companies, Business card application process in Finland, Corporate card benefits comparison in Sweden, Low-interest business credit cards in Finland, Corporate cards with no annual fee in Sweden, Business cards with travel rewards in Finland, Corporate credit cards for startups in Sweden, Business debit vs credit cards in Finland, Corporate card expense tracking features in Sweden"
      />

      {/* Card Action-Oriented Keywords */}
      <meta 
        name="keywords" 
        content="Apply for a business credit card, Get a corporate card in Finland, Sign up for Nordea business card, Open a business account with OP, Request a corporate card from SEB, Compare business cards from Danske Bank, Find the best business card offers, Upgrade to a corporate card, Contact Aktia for business credit solutions, Schedule a consultation with Handelsbanken"
      />

      {/* Card Problem-Solving Keywords */}
      <meta 
        name="keywords" 
        content="How to manage business expenses with corporate cards, Solutions for employee spending control, Benefits of using business credit cards, How to reduce business travel costs, Managing cash flow with corporate cards, Preventing fraud with business credit cards, Tracking employee expenses efficiently, Choosing the right business card for your company, Understanding corporate card fees, Improving business credit scores"
      />

      {/* Card Trending Keywords */}
      <meta 
        name="keywords" 
        content="Digital business credit cards, Virtual corporate cards, Contactless payment solutions for businesses, Mobile wallet integration for corporate cards, AI-driven expense management, Sustainable business credit cards, Cryptocurrency rewards on business cards, Subscription-based corporate card services, Blockchain technology in corporate payments, Biometric security for business cards"
      />
    </Helmet>
  )
}

export const GeneralSEOFi = () => {
  return (
    <Helmet>
      {/* Finland-Specific Keywords */}
      <meta 
        name="keywords" 
        content="Kulujen hallinta Suomi, Yrityskortit edullisesti, Paras yrityskortti Suomessa, Automatisoitu kuitinhallinta, Edulliset kuluratkaisut yrityksille, Ilmainen kulujen seurantasovellus, Pienyrityksen taloudenhallinta, Paras sovellus kuittien hallintaan, Matkakulujen hallinta Suomessa, Työntekijöiden kulukorvaukset"
      />

      {/* Finnish Local Intent Keywords */}
      <meta 
        name="keywords" 
        content="Paras matkalaskusovellus Suomessa, Yritysten kuitinhallintaohjelma, Suomalainen kulujenhallintaohjelma, Paikallinen kuluseuranta Suomessa, Yrityksen taloudenhallinta Espoossa, Ilmainen kulujen seurantasovellus, Edulliset yrityskortit, Budjettiystävälliset kulujen hallintatyökalut, Yksinkertainen kulujen hallinta, Ilmainen kuitinhallintasovellus, Edulliset yrityksen kuluratkaisut, Pienyrityksen kulujen seuranta, Kulujen hallinta startupeille, Ilmainen matkakulujen hallinta, Tee-se-itse kuluraportointi, Kulujen hallinta freelancereille, Avoimen lähdekoodin kulutyökalut, Ilmainen hyvityslaskuri, Edullinen työntekijöiden kulujen seuranta, Freelancerin kuitin organisointi, Edulliset taloudenhallintasovellukset, Kuluraportoinnin mallit, Yksinkertaiset mobiilikulusovellukset, Pienten tiimien kuluratkaisut, Ilmainen verkkokulukalkulaattori"
      />
    </Helmet>
  )
}

export const GeneralSEOSv = () => {
  return (
    <Helmet>
      {/* Sweden-Specific Keywords */}
      <meta 
        name="keywords" 
        content="Utgiftshantering Sverige, Företagskort med förmåner, Bästa företagskortet i Sverige, Automatiserad kvittohantering, Prisvärda lösningar för företagsutgifter, Gratis app för utgiftsspårning, Ekonomihantering för småföretag, Enkel kvittohantering i mobilen, Resekostnadshantering i Sverige, Verktyg för anställdas utgifter"
      />

      {/* Swedish Local Intent Keywords */}
      <meta 
        name="keywords" 
        content="Företagskort för resor i Sverige, Kvittohantering för småföretagare, Lokala lösningar för utgiftsspårning, Lokal utgiftshantering i Stockholm, Småföretagsekonomi Göteborg, Gratis verktyg för utgiftshantering, Prisvärda företagskort, Budgetvänliga utgiftshanteringsverktyg, Enkel hantering av utgifter, Gratis kvittohanteringsapp, Prisvärda lösningar för företagsutgifter, Utgiftshantering för småföretag, Utgiftshantering för startups, Gratis verktyg för reseutgifter, DIY utgiftsrapportering, Utgiftshantering för frilansare, Öppen källkod för utgiftshantering, Gratis ersättningskalkylator, Prisvärda verktyg för anställdas utgifter, Frilansares kvittohantering, Prisvärda appar för ekonomihantering, Mall för utgiftsrapportering, Enkla appar för mobila utgifter, Lösningar för små team och utgifter, Gratis onlineutgiftskalkylator"
      />
    </Helmet>
  )
}

export const GeneralMetaTags = () => {
  const { locale } = useParams()
  const location = useLocation()
  const pathWithoutLocale = location.pathname.replace(/^\/(en|fi|sv)/, '') || '/'
  const baseUrl = 'https://zevoy.com'

  return (
    <>
      <Helmet>
        {/* Canonical URL */}
        <link rel="canonical" href={`${baseUrl}${location.pathname}`} />
        
        {/* Language alternates for current page */}
        <link rel="alternate" hrefLang="en" href={`${baseUrl}/en${pathWithoutLocale}`} />
        <link rel="alternate" hrefLang="fi" href={`${baseUrl}/fi${pathWithoutLocale}`} />
        <link rel="alternate" hrefLang="sv" href={`${baseUrl}/sv${pathWithoutLocale}`} />
        <link rel="alternate" hrefLang="x-default" href={`${baseUrl}/en${pathWithoutLocale}`} />
      </Helmet>
      {locale === 'fi' ? (
        <GeneralSEOFi />
      ) : locale === 'sv' ? (
        <GeneralSEOSv />
      ) : (
        <GeneralSEO />
      )}
    </>
  )
}