import React, { useEffect, useMemo, useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { MiniTitle } from '../../common/styled'

import { CustomerCard } from './CustomerCard'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useIsMobile, useRelativeScrollPosition, useVerticalScrollWithThrottle } from '../../common/hooks'
import { useWindowHeight } from '@react-hook/window-size'
import { Inline, Stack } from '../../common/components/Spacing'
import { useParams } from 'react-router-dom'
import {
  CUSTOMER_CARD_WIDTH,
  CUSTOMER_NUMBER,
  CustomerCasesContent,
} from '../../common/constants'
import { LocaleIndex } from '../../common/types'
import { getCardCta, getCardTitleById } from '../utils'

const CustomerTitle = tw(MiniTitle)`text-[32px] text-center w-[280px]`

export const AlisaPankkiLogo = tw(CustomerCasesContent.alisa_pankki.logo)`w-[140px] text-center mt-[100px]`
export const AlisaPankkiTitle = tw(CustomerTitle)`mt-[40px]`

const DekatiLogo = tw(CustomerCasesContent.dekati.logo)`mt-[80px] w-[120px] text-center mt-[5px]`
const DekatiTitle = tw(CustomerTitle)`mt-[65px] text-[28px]`

const DlaPiperLogo = tw(CustomerCasesContent.dla_piper.logo)`w-[216px] text-center mt-[90px] h-[100px]`
const DlaPiperTitle = tw(CustomerTitle)`mt-[220px] text-[28px]`

const FingersoftLogo = tw(CustomerCasesContent.fingersoft.logo)`w-[122px] text-center mt-[60px] h-[100px]`
const FingersoftTitle = tw(CustomerTitle)`mt-[55px] text-[28px]`

const HansaMedicalLogo = tw(CustomerCasesContent.hansa_medical.logo)`w-[122px] text-center mt-[80px]`
const HansaMedicalTitle = tw(CustomerTitle)`mt-[55px] text-[28px]`

export const GasmetTechnologiesLogo = tw(CustomerCasesContent.gasmet_technologies.logo)`w-[122px] text-center mt-[100px]`
export const GasmetTechnologiesTitle = tw(CustomerTitle)`mt-[50px] w-[280px]`

const KorkiaLogo = tw(CustomerCasesContent.korkia.logo)`w-[216px] text-center mt-[67px]`
const KorkiaTitle = tw(CustomerTitle)`mt-[65px]`

const NetumLogo = tw(CustomerCasesContent.netum.logo)`w-[216px] text-center mt-[85px]`
const NetumTitle = tw(CustomerTitle)`mt-[60px]`

const OixioLogo = tw(CustomerCasesContent.oixio.logo)`w-[216px] text-center mt-[80px]`
const OixioTitle = tw(CustomerTitle)`mt-[48px] text-[30px]`

const PlugitLogo = tw(CustomerCasesContent.plugit.logo)`w-[216px] text-center mt-[67px]`
const PlugitTitle = tw(CustomerTitle)`mt-[60px]`

const ShowellLogo = tw(CustomerCasesContent.showell.logo)`w-[216px] text-center mt-[80px]`
const ShowellTitle = tw(CustomerTitle)`mt-[48px]`

const TypingMasterLogo = tw(CustomerCasesContent.typing_master.logo)`w-[216px] text-center mt-[67px]`
const TypingMasterTitle = tw(CustomerTitle)`mt-[48px]`

export const UprightProjectLogo = tw(CustomerCasesContent.upright_project.logo)`w-[216px] text-center mt-[67px]`
export const UprightProjectTitle = tw(CustomerTitle)`mt-[115px] text-[30px]`

export const VainuLogo = tw(CustomerCasesContent.vainu.logo)`w-[216px] text-center mt-[67px]`
export const VainuTitle = tw(CustomerTitle)`mt-[48px]  text-[30px]`

const Wrapper = tw.div``
const Tiles = tw(motion.div)`flex flex-wrap justify-center items-center gap-10 items-end`

// Mobile
const START_POSITION = 0

// END_POSITION = (Number of cards - 1) * card width + Number of cards * gap between cards
// Gap is equal to 30px
const END_POSITION = -((CUSTOMER_NUMBER - 1) * CUSTOMER_CARD_WIDTH + CUSTOMER_NUMBER * 30)
const CARD_SCROLLING_START_Y = 280

const MobileWrapper = styled(motion.div)`
  transform: translateX(${START_POSITION}px);
  position: static;
`

type CustomerCardsProps = {
  hasHeroSectionAppeared: boolean
  customerCases: any
}
export const CustomerCards = ({ customerCases, hasHeroSectionAppeared }: CustomerCardsProps) => {
  const isMobile = useIsMobile()
  const { locale } = useParams()
  const windowHeight = useWindowHeight()

  // Mobile
  const mobileRef = useRef<HTMLElement | null>(null)
  const [appeared, setAppeared] = useState(false)
  const mobileControls = useAnimation()
  const { scrollY } = useVerticalScrollWithThrottle()
  const relativeScrollY = useRelativeScrollPosition(mobileRef.current)

  // Desktop
  const appearanceRatio = isMobile ? `-${0.7 * windowHeight}px` : `-${0.8 * windowHeight}px`
  const [tilesRef, tilesInView] = useInView({
    threshold: 0,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const [tiles2Ref, tiles2InView] = useInView({
    threshold: 0,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const [tiles3Ref, tiles3InView] = useInView({
    threshold: 0,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const [tiles4Ref, tiles4InView] = useInView({
    threshold: 0,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const [tiles5Ref, tiles5InView] = useInView({
    threshold: 0,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })


  const firstTileControls = useAnimation()
  const secondTileControls = useAnimation()
  const thirdTileControls = useAnimation()
  const fourthTileControls = useAnimation()
  const fifthTileControls = useAnimation()
  const sixthTileControls = useAnimation()
  const seventhTileControls = useAnimation()
  const eighthTileControls = useAnimation()
  const ninthTileControls = useAnimation()
  const tenthTileControls = useAnimation()
  const eleventhTileControls = useAnimation()
  const twelfthTileControls = useAnimation()
  const thirteenthTileControls = useAnimation()
  const fourteenthTileControls = useAnimation()
  const fifteenthTileControls = useAnimation()

  const bottomOffsetRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const sequence = async () => {
      if (!appeared) {
        await mobileControls.start({
          opacity: 1,
          transition: { duration: 0.3, delay: 0.9 },
        })
        setAppeared(true)
        return
      }
    }

    if (isMobile) {
      sequence()
    }
  }, [mobileControls, isMobile, scrollY, relativeScrollY, appeared])

  // handle hub image horizontal and vertical movement
  // useCustomerCardsScrollAnimation({
  //   // 700 is the height of small phones like IPhone XS. This calculation centers the card vertically on the screen
  //   startPositionY: CARD_SCROLLING_START_Y - ((windowHeight - 700) / 2),
  //   mobileRef,
  //   startPositionX: START_POSITION,
  //   endPositionX: END_POSITION,
  //   speedMultiplier: 0.3,
  //   bottomOffsetRef
  // })


  const setBottomOffsetRef = (node: HTMLDivElement | null) => {
    bottomOffsetRef.current = node
  }
  const setRefs = (node: HTMLElement | null) => {
    mobileRef.current = node
  }

  const customerCards = useMemo(() => {
    return [
      // Row 1
      {
        id: CustomerCasesContent.fingersoft.id,
        imgUrl: CustomerCasesContent.fingersoft.cardImage,
        title: <FingersoftTitle>{getCardTitleById(CustomerCasesContent.fingersoft.prismicId?.[locale as LocaleIndex], customerCases)}</FingersoftTitle>,
        link: CustomerCasesContent.fingersoft.id,
        logo: <FingersoftLogo />,
        cta: getCardCta(CustomerCasesContent.fingersoft.prismicId?.[locale as LocaleIndex], customerCases),
        controls: firstTileControls,
      },
      {
        id: CustomerCasesContent.netum.id,
        imgUrl: CustomerCasesContent.netum.cardImage,
        title: <NetumTitle>{getCardTitleById(CustomerCasesContent.netum.prismicId?.[locale as LocaleIndex], customerCases)}</NetumTitle>,
        link: CustomerCasesContent.netum.id,
        logo: <NetumLogo />,
        cta: getCardCta(CustomerCasesContent.netum.prismicId?.[locale as LocaleIndex], customerCases),
        controls: secondTileControls
      },
      {
        id: CustomerCasesContent.dekati.id,
        imgUrl: CustomerCasesContent.dekati.cardImage,
        title: <DekatiTitle>{getCardTitleById(CustomerCasesContent.dekati.prismicId?.[locale as LocaleIndex], customerCases)}</DekatiTitle>,
        link: CustomerCasesContent.dekati.id,
        logo: <DekatiLogo />,
        cta: getCardCta(CustomerCasesContent.dekati.prismicId?.[locale as LocaleIndex], customerCases),
        controls: thirdTileControls
      },

      // Row 2
      {
        id: CustomerCasesContent.hansa_medical.id,
        imgUrl: CustomerCasesContent.hansa_medical.cardImage,
        title: <HansaMedicalTitle>{getCardTitleById(CustomerCasesContent.hansa_medical.prismicId?.[locale as LocaleIndex], customerCases)}</HansaMedicalTitle>,
        link: CustomerCasesContent.hansa_medical.id,
        logo: <HansaMedicalLogo />,
        cta: getCardCta(CustomerCasesContent.hansa_medical.prismicId?.[locale as LocaleIndex], customerCases),
        controls: fourthTileControls
      },
      {
        id: CustomerCasesContent.dla_piper.id,
        imgUrl: CustomerCasesContent.dla_piper.cardImage,
        title: <DlaPiperTitle>{getCardTitleById(CustomerCasesContent.dla_piper.prismicId?.[locale as LocaleIndex], customerCases)}</DlaPiperTitle>,
        link: CustomerCasesContent.dla_piper.id,
        // logo: <DlaPiperLogo />,
        logo: null, // logo is not allowed for dla piper
        cta: getCardCta(CustomerCasesContent.dla_piper.prismicId?.[locale as LocaleIndex], customerCases),
        controls: fifthTileControls
      },
      {
        id: CustomerCasesContent.gasmet_technologies.id,
        imgUrl: CustomerCasesContent.gasmet_technologies.cardImage,
        title: <GasmetTechnologiesTitle>{getCardTitleById(CustomerCasesContent.gasmet_technologies.prismicId?.[locale as LocaleIndex], customerCases)}</GasmetTechnologiesTitle>,
        link: CustomerCasesContent.gasmet_technologies.id,
        logo: <GasmetTechnologiesLogo />,
        cta: getCardCta(CustomerCasesContent.gasmet_technologies.prismicId?.[locale as LocaleIndex], customerCases),
        controls: sixthTileControls
      },

      // Row 3
      {
        id: CustomerCasesContent.korkia.id,
        imgUrl: CustomerCasesContent.korkia.cardImage,
        title: <KorkiaTitle>{getCardTitleById(CustomerCasesContent.korkia.prismicId?.[locale as LocaleIndex], customerCases)}</KorkiaTitle>,
        link: CustomerCasesContent.korkia.id,
        logo: <KorkiaLogo />,
        cta: getCardCta(CustomerCasesContent.korkia.prismicId?.[locale as LocaleIndex], customerCases),
        controls: seventhTileControls
      },
      {
        id: CustomerCasesContent.upright_project.id,
        imgUrl: CustomerCasesContent.upright_project.cardImage,
        title: <UprightProjectTitle>{getCardTitleById(CustomerCasesContent.upright_project.prismicId?.[locale as LocaleIndex], customerCases)}</UprightProjectTitle>,
        link: CustomerCasesContent.upright_project.id,
        logo: <UprightProjectLogo />,
        cta: getCardCta(CustomerCasesContent.upright_project.prismicId?.[locale as LocaleIndex], customerCases),
        controls: eighthTileControls
      },
      {
        id: CustomerCasesContent.oixio.id,
        imgUrl: CustomerCasesContent.oixio.cardImage,
        title: <OixioTitle>{getCardTitleById(CustomerCasesContent.oixio.prismicId?.[locale as LocaleIndex], customerCases)}</OixioTitle>,
        link: CustomerCasesContent.oixio.id,
        logo: <OixioLogo />,
        cta: getCardCta(CustomerCasesContent.oixio.prismicId?.[locale as LocaleIndex], customerCases),
        controls: ninthTileControls
      },

      // Row 4
      {
        id: CustomerCasesContent.plugit.id,
        imgUrl: CustomerCasesContent.plugit.cardImage,
        title: <PlugitTitle>{getCardTitleById(CustomerCasesContent.plugit.prismicId?.[locale as LocaleIndex], customerCases)}</PlugitTitle>,
        link: CustomerCasesContent.plugit.id,
        logo: <PlugitLogo />,
        cta: getCardCta(CustomerCasesContent.plugit.prismicId?.[locale as LocaleIndex], customerCases),
        controls: tenthTileControls
      },
      {
        id: CustomerCasesContent.showell.id,
        imgUrl: CustomerCasesContent.showell.cardImage,
        title: <ShowellTitle>{getCardTitleById(CustomerCasesContent.showell.prismicId?.[locale as LocaleIndex], customerCases)}</ShowellTitle>,
        link: CustomerCasesContent.showell.id,
        logo: <ShowellLogo />,
        cta: getCardCta(CustomerCasesContent.showell.prismicId?.[locale as LocaleIndex], customerCases),
        controls: eleventhTileControls
      },
      {
        id: CustomerCasesContent.typing_master.id,
        imgUrl: CustomerCasesContent.typing_master.cardImage,
        title: <TypingMasterTitle>{getCardTitleById(CustomerCasesContent.typing_master.prismicId?.[locale as LocaleIndex], customerCases)}</TypingMasterTitle>,
        link: CustomerCasesContent.typing_master.id,
        logo: <TypingMasterLogo />,
        cta: getCardCta(CustomerCasesContent.typing_master.prismicId?.[locale as LocaleIndex], customerCases),
        controls: twelfthTileControls
      },

      // Row 5
      {
        id: CustomerCasesContent.vainu.id,
        imgUrl: CustomerCasesContent.vainu.cardImage,
        title: <VainuTitle>{getCardTitleById(CustomerCasesContent.vainu.prismicId?.[locale as LocaleIndex], customerCases)}</VainuTitle>,
        link: CustomerCasesContent.vainu.id,
        logo: <VainuLogo />,
        cta: getCardCta(CustomerCasesContent.vainu.prismicId?.[locale as LocaleIndex], customerCases),
        controls: thirteenthTileControls
      },
      {
        id: CustomerCasesContent.alisa_pankki.id,
        imgUrl: CustomerCasesContent.alisa_pankki.cardImage,
        title: <AlisaPankkiTitle>{getCardTitleById(CustomerCasesContent.alisa_pankki.prismicId?.[locale as LocaleIndex], customerCases)}</AlisaPankkiTitle>,
        link: CustomerCasesContent.alisa_pankki.id,
        logo: <AlisaPankkiLogo />,
        cta: getCardCta(CustomerCasesContent.alisa_pankki.prismicId?.[locale as LocaleIndex], customerCases),
        controls: fourteenthTileControls
      },
      {
        id: CustomerCasesContent.company_placeholder.id,
        imgUrl: CustomerCasesContent.company_placeholder.cardImage,
        title: '',
        link: `/${locale}/contact`,
        logo: null,
        cta: getCardCta(CustomerCasesContent.company_placeholder.prismicId?.[locale as LocaleIndex], customerCases),
        controls: fifteenthTileControls
      }

    ]
  }, [customerCases, locale])

  if (isMobile) {
    return (
      <Stack gap={0}>
        <MobileWrapper initial={{ opacity: 0 }} animate={mobileControls} ref={setRefs}>
          <Stack gap={7.5}>
            {
              customerCards.map((card, index) => (
                <CustomerCard
                  key={index}
                  imgUrl={card.imgUrl}
                  title={card.title}
                  link={card.link}
                  logo={card.logo}
                  index={index}
                  translations={{ readMore: card.cta }}
                  threshold={0.2}
                  controls={card.controls}
                />
              ))
            }
          </Stack>
        </MobileWrapper>
        <div ref={setBottomOffsetRef} />
      </Stack>
    )
  }

  return (
    <Wrapper>
      <Tiles ref={tilesRef}>
        {
          customerCards.slice(0, 3).map((card, index) => (
            <CustomerCard
              key={index}
              imgUrl={card.imgUrl}
              title={card.title}
              link={card.link}
              logo={card.logo}
              index={index}
              controls={card.controls}
              readyToAppear={tilesInView}
              delay={index * 0.3}
              translations={{ readMore: card.cta }}
            />
          ))
        }
      </Tiles>
      <Tiles ref={tiles2Ref}>
        {
          customerCards.slice(3, 6).map((card, index) => (
            <CustomerCard
              key={index}
              imgUrl={card.imgUrl}
              title={card.title}
              link={card.link}
              logo={card.logo}
              index={index}
              controls={card.controls}
              readyToAppear={tiles2InView}
              delay={index * 0.3}
              translations={{ readMore: card.cta }}
            />
          ))
        }
      </Tiles>
      <Tiles ref={tiles3Ref}>
        {
          customerCards.slice(6, 9).map((card, index) => (
            <CustomerCard
              key={index}
              imgUrl={card.imgUrl}
              title={card.title}
              link={card.link}
              logo={card.logo}
              index={index}
              controls={card.controls}
              readyToAppear={tiles3InView}
              delay={index * 0.3}
              translations={{ readMore: card.cta }}
            />
          ))
        }
      </Tiles>
      <Tiles ref={tiles4Ref}>
        {
          customerCards.slice(9, 12).map((card, index) => (
            <CustomerCard
              key={index}
              imgUrl={card.imgUrl}
              title={card.title}
              link={card.link}
              logo={card.logo}
              index={index}
              controls={card.controls}
              readyToAppear={tiles4InView}
              delay={index * 0.3}
              translations={{ readMore: card.cta }}
            />
          ))
        }
      </Tiles>
      <Tiles ref={tiles5Ref}>
        {
          customerCards.slice(12, 15).map((card, index) => (
            <CustomerCard
              key={index}
              imgUrl={card.imgUrl}
              title={card.title}
              link={card.link}
              logo={card.logo}
              index={index}
              controls={card.controls}
              readyToAppear={tiles5InView}
              delay={index * 0.3}
              translations={{ readMore: card.cta }}
            />
          ))
        }
      </Tiles>
    </Wrapper>
  )
}
